<template>
  <v-row class="match-height">
    <v-col cols="12">
      <app-card-code :title="`${$t('menu.nomenclador')} / ${$t('menu.flights')} / ${$t('menu.airport')}`">
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="error"
                class="mb-4 me-3"
                :loading="loading"
                @click="$router.push({ name: 'airport-flights-list' })"
              >
                <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
                <span>{{ $t('btn.back') }}</span>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                v-if="!onlyShow"
                color="primary"
                class="mb-4 me-3"
                :loading="loading"
                @click="save()"
              >
                <span>{{ btnTitle }}</span>
                <v-icon>{{ icons.mdiUpdate }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-form
            v-if="!isLoading"
            class="multi-col-validation"
          >
            <v-row>
              <v-col
                cols="12"
                md="4"
              >
                <v-row class="pb-5">
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-text-field
                      v-model="airport.iata"
                      :disabled="onlyShow"
                      :label="$t('lbl.iata')"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-text-field
                      v-model="airport.name"
                      :disabled="onlyShow"
                      :label="$t('lbl.airport')"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-text-field
                      v-model="airport.country"
                      :disabled="onlyShow"
                      :label="$t('lbl.country')"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-text-field
                      v-model="airport.state"
                      :disabled="onlyShow"
                      :label="$t('lbl.provinceEstado')"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-text-field
                      v-model="airport.city"
                      :disabled="onlyShow"
                      :label="$t('lbl.locality')"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-textarea
                      v-model="airport.direccion"
                      :disabled="onlyShow"
                      :label="$t('lbl.address')"
                      outlined
                      no-resize
                      rows="3"
                      row-height="15"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                md="8"
              >
                <v-row class="pb-5">
                  <!--<v-col
                    cols="12"
                    md="12"
                  >
                    {{ showMap === true ? `${$t('lbl.hideMap')}` : `${$t('lbl.showMap')}` }}
                    <v-btn
                      class="mx-2"
                      outlined
                      fab
                      dark
                      x-small
                      color="primary"
                      @click="showMap = !showMap"
                    >
                      <v-icon v-if="!showMap">
                        {{ icons.mdiMapPlus }}
                      </v-icon>
                      <v-icon v-else>
                        {{ icons.mdiMapMinus }}
                      </v-icon>
                    </v-btn>
                  </v-col>-->
                  <v-col
                    v-if="showMap"
                    cols="12"
                    md="12"
                  >
                    <GeoTag v-if="!editGeoTag" />
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="$store.state.app.geotag.longitud"
                      disabled
                      :label="$t('lbl.longitud')"
                      outlined
                      dense
                      hide-details
                      @input="setGeoTag"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="$store.state.app.geotag.latitud"
                      disabled
                      :label="$t('lbl.latitud')"
                      outlined
                      dense
                      hide-details
                      @input="setGeoTag"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </app-card-code>
    </v-col>
  </v-row>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiCity,
  mdiMapPlus,
  mdiMapMinus,
} from '@mdi/js'

// eslint-disable-next-line import/no-unresolved
import GeoTag from '@/views/nomencladores/utils/GeoTagAirport.vue'

export default {
  components: {
    AppCardCode,
    GeoTag,
  },
  data() {
    return {
      isLoading: true,
      editGeoTag: true,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiCity,
        mdiMapPlus,
        mdiMapMinus,
      },
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      modelTimeConfig: {
        type: 'string',
        mask: 'HH:mm',
      },
      timezone: '',
      aeronaves: [],
      aerolineas: [],
      semanas: [],
      modelSemanas: [],
      model: {},
      showMap: true,
      loading: false,

      ptosRecogida: [],
      modelPtosRecogida: [],

      items: [],
      itemsOrigen: [],
      searchOrigen: null,
      itemsDestino: [],
      searchDestino: null,
    }
  },
  computed: {
    btnTitle() {
      return localStorage.getItem('airport-id') === null ? this.$t('btn.send') : this.$t('btn.update')
    },
    ...mapState({
      airport: state => state.app.airport,
      onlyShow: state => state.app.onlyShow,
    }),
  },
  created() {
    this.getItem()

    if (localStorage.getItem('airport-show') !== null) {
      this.setOnlyShow(true)
    } else {
      this.setOnlyShow(false)
    }
  },
  methods: {
    ...mapMutations(['updateGeoTag', 'updateAirport', 'editAirport', 'setOnlyShow']),

    getItem() {
      if (localStorage.getItem('airport-id') !== null) {
        const id = localStorage.getItem('airport-id')
        this.axios
          .get(`airport/${id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            this.model = res.data.data.data

            let country = null
            let state = null
            let city = null
            let direccion = null
            if (this.model.country !== null) {
              // eslint-disable-next-line no-const-assign
              country = this.model.country
            }
            if (this.model.state !== null) {
              // eslint-disable-next-line no-const-assign
              state = this.model.state
            }
            if (this.model.city !== null) {
              // eslint-disable-next-line no-const-assign
              city = this.model.city
            }
            if (this.model.direccion !== null) {
              // eslint-disable-next-line no-const-assign
              direccion = this.model.direccion
            }

            const json = {
              iata: this.model.iata,
              name: this.model.name,
              country,
              state,
              city,
              direccion,
            }
            this.editAirport(json)
            this.updateGeoTag({ longitud: this.model.longitude, latitud: this.model.latitude })

            setTimeout(() => {
              this.editGeoTag = false
            }, 100)
          })
          // eslint-disable-next-line no-return-assign
          .finally(() => (this.isLoading = false))
      } else {
        this.isLoading = false
        setTimeout(() => {
          this.editGeoTag = false
        }, 50)
      }
    },

    save() {
      if (
        this.airport.iata
        && this.airport.name
        && this.airport.country
        && this.airport.state
        && this.airport.city
        && this.airport.direccion
        && this.$store.state.app.geotag.longitud
        && this.$store.state.app.geotag.latitud
      ) {
        this.loading = true
        const json = {
          iata: this.airport.iata,
          name: this.airport.name,
          country: this.airport.country,
          state: this.airport.state,
          city: this.airport.city,
          direccion: this.airport.direccion,
          longitud: this.$store.state.app.geotag.longitud,
          latitud: this.$store.state.app.geotag.latitud,
        }

        // console.log(json)
        this.updateAirport({
          iata: null,
          name: null,
          country: null,
          state: null,
          city: null,
          direccion: null,
        })

        if (localStorage.getItem('airport-id') === null) {
          this.axios
            .post('airport', json, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              // console.log(response)
              if (response.data.success === false) {
                if (response.data.data.status === 401) {
                  // this.$toast.error(this.$t('msg.noAutorice'))
                } else if (response.data.data.status === 402) {
                  this.$toast.error(this.$t('msg.airportExist', { n: this.airport.iata }))
                }
              } else {
                localStorage.removeItem('airport-id')
                this.$router.push({ name: 'airport-flights-list' })
              }
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line no-return-assign
            .finally(() => (this.loading = false))
        } else {
          this.axios
            .put(`airport/${this.model.id}`, json, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              if (response.data.success === false) {
                // this.$toast.error(this.$t('msg.noAutorice'))
              } else {
                localStorage.removeItem('airport-id')
                this.$router.push({ name: 'airport-flights-list' })
              }
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line no-return-assign
            .finally(() => (this.loading = false))
        }
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
    setGeoTag() {
      if (this.$store.state.app.geotag.longitud && this.$store.state.app.geotag.latitud) {
        this.editGeoTag = true
        const geotag = { longitud: this.$store.state.app.geotag.longitud, latitud: this.$store.state.app.geotag.latitud }
        this.updateGeoTag(geotag)
        setTimeout(() => {
          this.editGeoTag = false
        }, 50)
      }
    },
  },
}
</script>
